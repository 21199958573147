v<template>
  <v-container class="fill-height fullscreen-bg" fluid> 
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8" md="6">
        <v-card class="elevation-12">
          <EventHeader v-if="event" :event="event" compact/>
          <GroupHeader v-if="org" :group="org" compact/>
          <PageHeader v-if="tenant.id == 'wmm'" title="" :img="tenant.logoUrl" compact/>

          <v-card-text v-if="event && event.onboarding_msg" class="align-left my-0 pb-0">
            <vue-markdown class="markdown" :html="false" :source="event.onboarding_msg" />
          </v-card-text>

          <v-card-text v-if="tenant.id == 'wmm'" class="align-left my-0 pb-0">
            <h3>{{tenant.name}}</h3>
            <p>Connect to get started.</p>
          </v-card-text>

          <div v-if="!ssoRequired"> 
            <v-card-title class="headline">{{$t('account.register.title')}}</v-card-title>
          </div>

          <v-card-text class="py-0" :style="{order:providerOrder('terms')}">
            <v-checkbox v-model="termsAccepted" hide-details ref="termsCheckbox" class="terms-checkbox">
              <template v-slot:label>
                <div class="d-flex" style="flex-direction:column;">
                  <i18n path="account.register.terms_privacy_v2" tag="div" class="">
                    <router-link to='privacypolicy'>{{$t('account.register.terms_privacy_privacy')}}</router-link>
                    <router-link to='termsofservice'>{{$t('account.register.terms_privacy_terms')}}</router-link>
                  </i18n>
                  <div class="text-muted">{{$t('account.register.terms_privacy_subtext')}}</div>
                </div>
              </template>
            </v-checkbox>
          </v-card-text>

          <v-card-text v-if="ssoEnabled && showCustomConsentMessage" class="py-0 px-4"> 
            <v-form ref="ssoForm" lazy-validation>
              <v-checkbox v-model="eventConsentGiven" :rules="consentRules">
                <template v-slot:label>
                  <VueMarkdown class="markdown-content">{{event.join_consent_msg}}</VueMarkdown>
                </template>
              </v-checkbox>
            </v-form>
          </v-card-text>
          
          <v-card-text v-if="ssoEnabled" class="py-4 px-4"> 
            <div v-if="tenant.id === 'wmm'">
              <p class="align-left">Login with your existing World Marathon Majors account, or register for a new account.</p>
              <v-btn block color="primary"  @click="validateSsoState() && startSso()">
                Connect
              </v-btn>            
            </div>
            <div v-else>
              <p class="align-left">{{ $t('account.sso-intro-msg') }}</p>
              <v-btn block color="primary"  @click="validateSsoState() && startSso()">
                Connect with Single Sign-On
              </v-btn>            
            </div>
          </v-card-text>
          <div v-if="!ssoRequired"> 
            <div class="provider-container">
              <v-alert v-if="allProvidersVisible" type="info" tile>
                You can log in using a connected account. When you use this option, please make sure to always log back in with the exact same account type.
              </v-alert>

              <div class="ma-4 mb-0 provider provider-connect" :style="{order:providerOrder('strava'), display: providerDisplay('strava')}">
                <v-btn v-if="providerUrls" :href="providerUrls.strava" block color="#FC4C02" v-on:click="ensureTermsAreApproved">
                  <img src="/images/btn_strava_connectwith_orange.svg" style="height:42px"/>
                </v-btn>
              </div>
              
              <div class="ma-4 mb-0 provider provider-connect" :style="{order:providerOrder('garmin'), display: providerDisplay('garmin')}">
                <v-btn v-if="providerUrls" :href="providerUrls.garmin" block color="#11BFED" v-on:click="ensureTermsAreApproved">
                  <img src="/images/garmin-connect.png" width="26" height="26" alt="Garmin Connect">
                  <v-icon  left>mdi-pencil</v-icon> Garmin Connect
                </v-btn>
              </div>
              
              <div class="ma-4 mb-0 provider provider-connect" :style="{order:providerOrder('polar'), display: providerDisplay('polar')}">
                <v-btn v-if="providerUrls" :href="providerUrls.polar" block color="#d10027" class="white--text" v-on:click="ensureTermsAreApproved">
                  <span style="font-size:70%;">Connect with</span>
                  <img src="/images/polar.png" height="22" alt="Polar" class="ml-2">
                </v-btn>
              </div>
              <div class="ma-4 mb-0 provider provider-connect" :style="{order:providerOrder('fitbit'), display: providerDisplay('fitbit')}">
                <v-btn v-if="providerUrls" :href="providerUrls.fitbit" block color="#4cc2c4" class="white--text" v-on:click="ensureTermsAreApproved">
                  <span style="font-size:70%;">Connect with</span>
                  <img src="/images/fitbit.png" height="22" alt="Fitbit" class="ml-2">
                </v-btn>
              </div>
              <div class="ma-4 mb-0 provider provider-connect" :style="{order:providerOrder('suunto'), display: providerDisplay('suunto')}">
                <v-btn v-if="providerUrls" :href="providerUrls.suunto" block color="#147eab" class="white--text" v-on:click="ensureTermsAreApproved">
                  <span style="font-size:70%;">Connect with</span>
                  <img src="/images/suunto.png" height="24" alt="Suunto" class="ml-2">
                </v-btn>
              </div>
              <div class="ma-4 mb-0 provider provider-connect" :style="{order:providerOrder('underarmour'), display: providerDisplay('underarmour')}">
                <v-btn v-if="providerUrls" :href="providerUrls.underarmour" block color="#ea212e" class="white--text" v-on:click="ensureTermsAreApproved">
                  <img src="/images/underarmour.png" height="20" alt="Under Armour" class="mr-2">
                  <span style="font-size:80%;" class="mr-1">Log In with</span>
                  <strong style="font-size:80%; text-transform:uppercase;">Under Armour</strong>
                </v-btn>
              </div>
              <div class="ma-4 mb-0 provider provider-connect" :style="{order:providerOrder('coros'), display: providerDisplay('coros')}">
                <v-btn v-if="providerUrls" :href="providerUrls.coros" block color="#F8283B" class="white--text" v-on:click="ensureTermsAreApproved">
                  <span style="font-size:70%;">Connect with</span>
                  <img src="/images/coros.png" height="16" alt="Coros" class="ml-2">
                </v-btn>
              </div>
              <div class="ma-4 mb-0 provider provider-connect" :style="{order:providerOrder('eventscom'), display: providerDisplay('eventscom')}">
                <v-btn v-if="providerUrls" :href="providerUrls.eventscom" block color="#00BCDA" class="white--text" v-on:click="ensureTermsAreApproved">
                  <span style="font-size:70%;">Connect with</span>
                  <img src="/images/eventscom-white.png" height="16" alt="Evens.com" class="ml-2">
                </v-btn>
              </div>
              <div class="ma-4 mb-0 provider provider-connect" :style="{order:providerOrder('concept2'), display: providerDisplay('concept2')}">
                <v-btn v-if="providerUrls" :href="providerUrls.concept2" block color="#033B78" class="white--text" v-on:click="ensureTermsAreApproved">
                  <span style="font-size:80%;" class="mr-1">Connect with</span>
                  <strong style="font-size:80%; text-transform:uppercase;">Concept2</strong>
                </v-btn>
              </div>
              <div class="ma-4 mb-0 provider provider-connect" :style="{order:providerOrder('asdeporte'), display: providerDisplay('asdeporte')}">
                <v-btn v-if="providerUrls" :href="providerUrls.asdeporte" block color="#FF6A00" class="white--text" v-on:click="ensureTermsAreApproved">
                  <span style="font-size:80%;" class="mr-1">Connect with</span>
                  <strong style="font-size:80%; text-transform:uppercase;">Asdeporte</strong>
                </v-btn>
              </div>
              <div class="ma-4 mb-0 provider provider-connect" :style="{order:providerOrder('slack'), display: providerDisplay('slack')}">
                <v-btn v-if="providerUrls" :href="providerUrls.slack" block color="#2EB67D" class="white--text" v-on:click="ensureTermsAreApproved">
                  <v-icon class="mr-2">fab fa-slack</v-icon>
                  <span style="font-size:70%;">Sign-In with Slack</span>
                </v-btn>
              </div>
              <div class="ma-4 mb-0 provider provider-connect" :style="{order:providerOrder('microsoft'), display: providerDisplay('microsoft')}">
                <v-btn v-if="providerUrls" :href="providerUrls.microsoft" block color="#F34F1C" class="white--text" v-on:click="ensureTermsAreApproved">
                  <v-icon class="mr-2">fab-microsoft</v-icon>
                  <span style="font-size:70%;">Sign-In with Microsoft</span>
                </v-btn>
              </div>
              <div v-if="!allProvidersVisible" class="ma-4 mb-0 provider provider-connect"  :style="{order:providerOrder('more')}">
                <v-btn block outlined color="grey" class="white--text" @click="showAllProviders()">
                  {{$t('account.more-connect-options')}}
                </v-btn>
              </div>
              <div v-if="allProvidersVisible" class="ma-4 mb-0 provider provider-connect"  :style="{order:providerOrder('more')}">
                <v-btn block outlined color="grey" class="white--text" @click="showDefaultProviders()">
                  {{$t('account.fewer-connect-options')}}
                </v-btn>
              </div>

              <v-card-text class="provider py-0" :style="{order:providerOrder('sodisp'), display: providerDisplay('sodisp')}">
                <v-alert v-if="error" type="error">{{error}}</v-alert>
                <v-form
                ref="form"
                v-model="valid"
                lazy-validation
                >
                <v-text-field
                    v-model="name"
                    :label="$t('account.register.displayname')"
                    prepend-icon="fa-user"
                    type="name"
                ></v-text-field>

                <v-text-field
                    v-model="email"
                    :rules="emailRules"
                    :label="$t('account.register.email')"
                    prepend-icon="fa-envelope"
                    type="email"
                    required
                ></v-text-field>

                <v-text-field
                    v-model="password"
                    :rules="passwordRules"
                    :label="$t('account.register.password')"
                    required
                    prepend-icon="fa-lock"
                    :append-icon="passwordShow ? 'fal fa-eye-slash' : 'fal fa-eye'"
                    :type="passwordShow ? 'text' : 'password'"
                    @click:append="passwordShow = !passwordShow"
                ></v-text-field>
                </v-form>
                <v-btn :disabled="!valid" block color="primary" @click="ensureTermsAreApproved() && validate()" :loading="$store.getters.isLoading">{{$t('account.register.create-account')}}</v-btn>
                <v-divider class="mt-4"/>
              </v-card-text>
            </div>
            <v-divider class="mt-4"/>
            <i18n v-if="false" path="account.register.terms_privacy" tag="v-card-text" class="text-muted">
              <router-link to='termsofservice'>{{$t('account.register.terms_privacy_terms')}}</router-link>
              <router-link to='privacypolicy'>{{$t('account.register.terms_privacy_privacy')}}</router-link>
            </i18n>
            <v-divider />
            <v-card-text class="text-center">
              <p>{{$t('account.register.login_text')}}</p>
              <v-btn outlined block color="primary" :to="{ name: 'login', query: $route.query }">{{$t('account.register.login_action')}}</v-btn>
            </v-card-text>
          </div>

        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import { mapGetters } from "vuex";
import axios from "axios";
import { EventBus } from '@/plugins/eventbus.js';
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword, updateProfile   } from "firebase/auth";
import VueMarkdown from '@/components/VueMarkdown.vue'
import profileService from "@/services/profileService";
import EventHeader from '@/components/EventHeader.vue';
import GroupHeader from '@/components/GroupHeader.vue';
import PageHeader from '@/components/PageHeader.vue';
import eventService from "@/services/eventService";
import accountService from "@/services/accountService";
import groupService from "@/services/groupService";
import tenants from '@/data/tenants.config'
import i18n from '@/i18n'
const tenant = tenants.current();

export default {
  components: {
    EventHeader,
    GroupHeader,
    PageHeader,
    VueMarkdown,
  },
  props: {
  },
  data: () => ({
    tenant: tenant,
    providers: tenant.accountProviders,
    providerUrls: null,
    event: null,
    org: null,
    termsAccepted: false,
    isLoggingInOrRegistering: false,
    isRegistered: false,
    passwordShow: false,
    allProvidersVisible: false,
    availableProviders: null,
    confirmPasswordShow: false,
    eventConsentGiven: false,
    valid: true,
    error: null,
    name: null,
    email: '',
    emailRules: [
      v => !!v || i18n.t('account.register.email-is-required'),
      v => /.+@.+/.test(v) || i18n.t('account.register.email-must-be-valid')
    ],
    password: '',
    confirmPassword: '',
    passwordRules: [
      v => !!v || 'Password and Confirm password Required'
    ]
  }),

  async mounted() {

    if (this.$route.query.providers) {
      this.providers = this.$route.query.providers.replace(' ', '').split(',');
    }
    if (this.$route.query.event) {
      this.event = (await eventService.get(this.$route.query.event)).data;
      if (this.event && this.event.allowed_providers && this.event.allowed_providers.length > 0 && !this.$route.query.providers) {
        const customProviders = this.event.allowed_providers;
        customProviders.push('sodisp');
        this.providers = customProviders;
      }
      if (this.event && this.event.beta_login) {
        this.providers = ['sodisp'];
      }
    }
    if (this.$route.query.org) {
      this.org = (await groupService.get(this.$route.query.org)).data;
    }
    
    const options = { 
      referral: this.$route.query.referral, 
      eventId: this.$route.query.event, 
      orgId: this.$route.query.org, 
      verificationCode: this.$route.query.verificationCode, 
      joinGroupId: this.$route.query.joinGroupId, 
      view: this.viewMode
    };
    this.providerUrls = this.$oauth.getProviderUrls(options, this.event);

    EventBus.$on('login-state-change', async user => {
      if (!this.isLoggingInOrRegistering) {
        // note: we do NOT redirect when we are initiating the state change as we want to finish up first and then do so ourselves
        await this.redirectWhenLoggedIn();
      }
    });
    await this.redirectWhenLoggedIn();
  },

  methods: {

    providerOrder(provider) {
      if (provider === 'terms') return this.providerOrder('sodisp')+1;
      if (provider === 'more') return 99;
      return this.providers.indexOf(provider);
    },
    providerDisplay(provider) {
      return this.providers.includes(provider) ? '' : 'none';
    },
    ensureTermsAreApproved(event) {
      if (!this.termsAccepted) {
        console.log('///', event);
        if (event) event.preventDefault();
        let elem = this.$refs.termsCheckbox;
        if (elem) {
          elem.$el.classList.add('shake');
          this.$toast.error(i18n.t('account.register.terms_privacy_validation'));
          setTimeout(() => {
            let elem = this.$refs.termsCheckbox;
            if (elem) elem.$el.classList.remove('shake');
          }, 500);
        }
        return false;
      }
      return true;
    },

    validateSsoState(event) {
      var result = true;
      if (this.$refs.ssoForm) {
        result &= this.$refs.ssoForm.validate();
      }
      result &= this.ensureTermsAreApproved(event);
      return result;
    },

    startSso() {
      if (this.$refs.ssoForm && !this.$refs.ssoForm.validate()) {
        return;
      }
      this.$router.push({name: 'accountSso', query: { state: this.ssoState, eventId: this.event && this.event.id, view: this.viewMode } });
    },

    showAllProviders() {
      this.availableProviders = this.providers;
      this.providers = tenant.availableProviders ? tenant.availableProviders : tenant.accountProviders;
      //this.providers = this.providers.filter(x => x != 'sodisp');
      this.allProvidersVisible = true;
    },
    showDefaultProviders() {
      this.providers = this.availableProviders;
      this.allProvidersVisible = false;
    },

    async redirectWhenLoggedIn() {
      if (this.user) {
        this.redirect();
      }
    },

    async validate () {
      if (this.$refs.form.validate()) {
        this.snackbar = true
        await this.registerWithFirebase()
      }
    },

    reset () {
      this.$refs.form.reset()
    },

    async redirect() {
      var redirect = this.$route.query.redirect;
      var eventId = this.$route.query.event;
      var orgId = this.$route.query.org;
      var verificationCode = this.$route.query.verificationCode;
      var joinGroupId = this.$route.query.joinGroupId;
      if (eventId && this.isRegistered) {
        const joinResult = (await profileService.joinEvent(eventId)).data;
        if (joinResult && joinResult.status !== "OK") {
          // Go to join page for additional feedback when it couldn't join
          this.$router.replace({ name: "eventJoin", params: { id: eventId}, query: {verificationCode: verificationCode, joinGroupId: joinGroupId } });
          return;
        }
      }
      if (orgId && this.isRegistered) {
        await profileService.joinGroup(orgId, { code: verificationCode, joinGroupId: joinGroupId });
      }

      if (eventId) {
        this.$router.push({ name: 'eventWelcome', params: { id: eventId }, query: {verificationCode:verificationCode, joinGroupId: joinGroupId} });
        return;
      }
      if (orgId) {
        this.$router.push({ name: 'organizationWelcome', params: { id: orgId }, query: {verificationCode:verificationCode, joinGroupId: joinGroupId} });
        return;
      }
      if (redirect) {
        if (redirect === 'create-event'){
          this.$router.replace({ name: "eventmanagerCreate" });
          return;
        }
        else {
          window.location.href = redirect;
        }
      }
      this.$router.replace({ name: "profile" });
    },

    async prepareProfile() {
      var ip = null;
      try {
        ip = (await axios.get('https://api.ipify.org?format=json')).data.ip;
      }
      catch {  }
      await profileService.post({ referral: this.$route.query.referral, ip: ip, event_id: this.$route.query.event }); 
    },

    async registerWithFirebase () {
      const auth = getAuth();
      const user = {
        email: this.email,
        password: this.password,
        name: this.name,
      }
      try {
        // note: uncomment the next line to force China proxy login flow 
        //throw { code: 'auth/network-request-failed', message: 'Network request failed. '};

        this.isLoggingInOrRegistering = true;
        var response = await createUserWithEmailAndPassword(auth, user.email, user.password);
        if (this.name) {
            await updateProfile(auth.currentUser, { displayName: user.name });
        }
        this.isRegistered = true;
        await this.$store.dispatch('setUser', response.user);
        this.$analytics.event('login', { method : 'Email' });
        await this.prepareProfile();

        this.redirect();
      }
      catch (ex) {
        if (ex.code == 'auth/network-request-failed') {
          this.tryRegisterWithApi();
          return;
        }
        try{
          // try to login with these credentials, might be someone trying to login
          this.isLoggingInOrRegistering = true;
          var loginResponse = await signInWithEmailAndPassword(auth, this.email, this.password);
          if (loginResponse && loginResponse.user) {
            await this.$store.dispatch("setUser", loginResponse.user);
            this.$analytics.event('login', { method : 'Email' });
            this.redirect();
            return;
          }
        }
        catch (ex){}

        this.error = ex.message;
      }
    },

    async tryRegisterWithApi() {
      console.log('[auth] Trying proxy register...');
      try {
        var response = await accountService.register(this.email, this.password, this.name);
        if (response.status == 200 && response.data && response.data.id_token) {
          console.log('[auth] Successful proxy register. Saving session information.');
          // note: we can't yet store a cookie, so this login session will last at most 1 hour and only in the current tab
          // store id token so we keep using the           
          this.isRegistered = true;
          await this.$store.dispatch("setUserAndToken", { email: this.email, displayName: this.name, idToken: response.data.id_token, expiresAt: response.data.expires_at });
          this.$analytics.event('login', { method : 'Email' });
          await this.prepareProfile();
          
          await this.redirect();
        }
      }
      catch (ex) {
        console.log('Error', ex);
        this.error = this.$t('account.login.invalid-credentials');
      }
    },    
  },
  computed: {
    ...mapGetters({
      user: "user"
    }), 
    ssoEnabled() {
      return (this.event && this.event.sso_enabled) || (tenant.ssoConfig && tenant.ssoConfig.enabled);
    },
    ssoRequired() {
      return this.event && this.event.sso_required || (tenant.ssoConfig && tenant.ssoConfig.required);
    },
    ssoState() {
      return JSON.stringify({
        eventId: this.event && this.event.id,
        orgId: this.org && this.org.id,
        view: this.viewMode,
        tenant: tenant.id,
        mode: this.$route.query.mode,
        verificationCode: this.$route.query.verificationCode,
        joinGroupId: this.$route.query.joinGroupId,
        ecg: this.eventConsentGiven,
      });
    },
    showCustomConsentMessage() {
      return this.event && this.ssoEnabled && this.event.join_consent_msg;
    },
    consentRules() {
      return !this.showCustomConsentMessage ? [] : [
        v => !!v || this.$t('events.welcome.consent-validation-msg'),
      ];
    },

    viewMode() {
      // note: somehow vue doesn't correctly read it from route.query so get it manually
      const viewFromUrl = new URLSearchParams(window.location.search).get("view");
      return viewFromUrl || this.$route.query.view || this.$store.state.view;
    },

  },
}
</script>
<style lang="scss">
.view--embedded #content, .view--app #content { min-height: inherit !important; }

.fullscreen-bg {
    margin-top: 60px;
    padding-top: 40px;
    background-image: url(https://sodisp.imgix.net/hero-cycling-1.jpg?w=2000&h=2000&fit=crop);
    background-size: cover;
    background-position: 50%;

    .row { margin: 0!important; }
  }
  .view--embedded .fullscreen-bg { margin-top: 0;}

  .provider-container {
    display: flex;
    flex-direction: column;

  }

  .terms-checkbox .v-input__slot {align-items: start;}

  // Shake animation
  @mixin ballb($yaxis: 0) {
    transform: translate3d($yaxis, 0, 0);
  }
  @keyframes bouncein {
    0%, 50% { @include ballb(-5px); }
    25%, 75%, 100% { @include ballb() }
  }
  .shake {
    animation: bouncein 500ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  }
</style>

